/* 

About

*/

.about__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 10px;
}

.about__img {
    width: 280px;
    height: 280px;
    border-radius: 50%;
}

.about__para {
    font-size: 21px;
    line-height: 2;
    max-width: 600px;
    width: 100%;
    padding: 8px 16px;
}
.about__me---link {
    margin-left: 8px;
    cursor: pointer;
}
