footer {
    background-color: #242424;
    color: rgb(245, 245, 245);
    z-index: 2;
}

.footer_logo {
    width: 60px;
    height: 60px;
}

.footer__links {
    display: flex;
    max-width: 500px;
        width: 100%;
        justify-content: space-between;
}

.footer__link {
    color: rgb(245, 245, 245);
}

.footer__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
        max-width: 100%;
}

.footer__logo--popper {
    position: absolute;
    right: 0;
    top: 16px;
    font-weight: 700;
    opacity: 0;
    transition: all 300ms ease;
    color: white;
}

.footer__anchor {
    position: relative;
}

.footer__anchor:hover .footer__logo--popper {
    transform: translateX(60px);
    opacity: 1;
}