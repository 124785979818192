/* 

Projects


*/

.project {
    margin-bottom: 100px;
}

.project__img {
    transition: all 600ms ease;
    display: flex;
    border-radius: 8px;
    width: 100%;
    box-shadow:
        0 4px 8px 0 rgba(0, 0, 0, 0.2),
        0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.project__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.project__img--wrapper {
    max-width: 45%;
    width: 100%;
    position: relative;
}
.project__separator {
    width: 4px;
    height: 100px;
    background: linear-gradient(0deg, rgba(34, 193, 195, 1) 0%, #7645d9 100%);
    margin: 0 auto;
}

.project__separator__second {
    width: 125%;
    height: 4px;
    position: absolute;
}

.project__separator__third {
    position: absolute;
    width: 4px;
    height: 100px;
    background-color: #7645d9;
}
.second__separator--right {
    right: 49.65%;
    background: linear-gradient(-90deg, rgba(34, 193, 195, 1) 0%, #7645d9 100%);
}
.second__separator--left {
    left: 49.65%;
    background: linear-gradient(90deg, rgba(34, 193, 195, 1) 0%, #7645d9 100%);
}
.third__separator--right {
    right: 174%;
}
.third__separator--left {
    left: 174%;
}
.project__description {
    max-width: 45%;
    width: 100%;
}

.fa-link {
    color: #7645d9;
}
.project__wrapper--even {
    flex-direction: row-reverse;
}

.project__wrapper--bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #1c1d25;
    opacity: 0;
    transition: all 450ms ease;
    visibility: hidden;
    z-index: 100;
}

.project__description--title {
    font-size: 40px;
}

.project__description--sub-title {
    margin-top: 8px;
}

.project__description--link {
    color: #1c1d25;
    font-size: 24px;
}

.project__description--para {
    margin: 16px 0;
}
.project__title--wrapper {
    display: flex;
    align-items: center;
    gap: 48px;
}
.project__description--height {
    height: 300px;
}

@media (max-width: 960px) {
    .project__wrapper {
        flex-direction: column-reverse;
        gap: 16px;
    }
    .project__img--wrapper,
    .project__description {
        max-width: 600px;
        width: 80%;
    }
    .project__separator__second,
    .project__separator__third {
        display: none;
    }
    .project {
        margin-bottom: 0px;
    }
    .project__description--height {
        height: auto;
    }
    .project__separator {
        height: 80px;
    }
}
