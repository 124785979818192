/* 

NAVIGATION


*/

nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    height: 100px;
    padding: 0 20px;
}

#personal-logo {
    filter: invert(1);
    width: 50px;
    height: 50px;
}

.nav__link--list {
    display: flex;
    justify-content: space-between;
}

.nav__link {
    margin: 0 12px;
}

.nav__link--anchor {
    font-size: 16px;
    color: #242424;
    font-weight: 700;
    cursor: pointer;
}

.fa-adjust {
    font-size: 20px;
}

.dark-theme,
.dark-theme .project__description--title,
.dark-theme .project__description--sub-title,
.dark-theme .project__description--para,
.dark-theme .section__title,
.dark-theme .section__sub-title,
.dark-theme .nav__link--anchor,
.dark-theme .fa-adjust,
.dark-theme .header__para .dark-theme .about__para {
    color: white;
}

.dark-theme section {
    background-color: #242424;
}

.dark-theme #personal-logo {
    filter: none;
}

.dark-theme .mail__btn {
    color: #242424;
    background-color: white;
}

.dark-theme .scroll__icon:after,
.dark-theme .link__hover-effect:after {
    background-color: white;
}

.dark-theme .scroll__icon {
    border-color: white;
}
