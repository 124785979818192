/* 

LANDING PAGE

*/

#landing-page {
    height: 100vh;
}

header {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 1000px;
    padding: 0 30px;
}

.header__para {
    font-size: 24px;
    line-height: 2;
    max-width: 600px;
    width: 100%;
}

.social__list {
    margin-top: 16px;
    display: flex;
    flex-direction: row;
    gap: 12px;
    align-items: center;
}

.social__link {
    font-size: 14px;
    background-color: #7645d9;
    color: white;
    padding: 6px;
    height: 32px;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

.mail__btn {
    position: absolute;
    right: 42px;
    bottom: 32px;
    width: 70px;
    height: 70px;
    font-size: 32px;
    border-radius: 50%;
    border: none;
    cursor: pointer;
    background-color: #242424;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* 

SHAPES

*/

.shape {
    position: absolute;
    z-index: 0;
}

.shape--0 {
    top: 8vh;
    left: 8vw;
    animation-name: float0;
}

.shape--1 {
    top: 12vh;
    left: 50vw;
    animation-name: float1;
}

.shape--2 {
    top: 15vh;
    left: 90vw;
    animation-name: float2;
}

.shape--3 {
    top: 50vh;
    left: 5vw;
    animation-name: float0;
}

.shape--4 {
    top: 50vh;
    left: 60vw;
    animation-name: float1;
}

.shape--5 {
    top: 50vh;
    left: 80vw;
    animation-name: float2;
}

.shape--6 {
    top: 80vh;
    left: 5vw;
    animation-name: float0;
}

.shape--7 {
    top: 70vh;
    left: 60vw;
    animation-name: float1;
}

.shape--8 {
    top: 72vh;
    left: 90vw;
    animation-name: float2;
}
.scroll {
    cursor: pointer;
}

.floating-shapes {
    width: 100%;
    overflow: hidden;
}

.shape {
    animation-duration: 5s; /* Adjust the animation duration as needed */
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
}

@keyframes float0 {
    0% {
        transform: translate(0, 0);
    }
    50% {
        transform: translate(20px, 20px);
    }
    100% {
        transform: translate(0, 0);
    }
}

@keyframes float1 {
    0% {
        transform: translate(0, 0);
    }
    50% {
        transform: translate(-10px, 30px);
    }
    100% {
        transform: translate(0, 0);
    }
}

@keyframes float2 {
    0% {
        transform: translate(0, 0);
    }
    50% {
        transform: translate(10px, -10px);
    }
    100% {
        transform: translate(0, 0);
    }
}

@media (max-width: 576px) {
    .shape--5,
    .shape--4,
    .shape--3 {
        display: none;
    }

    .shape--6 {
        top: 80vh;
    }
    .shape--0 {
        top: 18vh;
        left: 8vw;
        animation-name: float0;
    }
    
    .shape--1 {
        top: 14vh;
        left: 50vw;
        animation-name: float1;
    }
    
    .shape--2 {
        top: 30vh;
        left: 80vw;
        animation-name: float2;
    }
    .shape--8 {
        top: 86vh;
        left: 80vw;
        animation-name: float2;
    }
}
