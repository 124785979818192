.contact__wrapper {
    max-width: 700px;
    width: 100%;
    margin: 0 auto;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
    overflow: hidden;
    border-radius: 28px;
}

.contact__form {
    background-color: #fff;
    border-radius: 5px;
    padding: 24px 64px;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    gap: 24px;
}

.contact__form>input {
    padding: 10px 5px;
    border: none;
    font-size: 16px;
    border-bottom: 2px solid #7645d960;
    ;
}

.contact__form>textarea {
    padding: 20px 0;
    border: none;
    border-bottom: 2px solid #7645d960;
    resize: none;
    font-size: 16px;
}

.contact__form>input:focus-visible,
.contact__form>textarea:focus-visible {
    outline: none;
}

.contact__form>input:-webkit-autofill,
.contact__form>input:-webkit-autofill:active,
.contact__form>input:-webkit-autofill:focus,
.contact__form>input:-webkit-autofill:hover {
    -webkit-background-clip: text;
    -webkit-text-fill-color: black !important;
}

.send {
    padding: 15px 25px;
    width: fit-content !important;
    border-radius: 10px;
    font-size: 16px;
    background: #7645d9;
    color: white;
    font-weight: bold;
    border: none;
}

.send:hover {
    transition: 0.1s;
    cursor: pointer;
    transform: rotate(360deg);
    transform: scale(1.01);
}

/* 

Responsiveness

*/

/* Small phones, tablets, large smart phones */
@media (max-width: 768px) {
    .title {
        font-size: 80px;
    }

    .header__para {
        font-size: 20px;
    }

    .about__para {
        font-size: 20px;
    }

    .about__wrapper {
        flex-direction: column;
    }

    .footer__links {
        width: 90%;
    }

    .mail__btn {
        display: none;
    }
}

/* Small phones */
@media (max-width: 480px) {
    .title {
        font-size: 52px;
    }

    .header__para {
        font-size: 18px;
    }

    .nav__link:first-child {
        display: none;
    }

    .about__para {
        font-size: 18px;
    }

    .about__img {
        width: 250px;
        height: 250px;
    }

    .contact__form {
        padding: 24px;
    }

    .footer__links {
        width: 90% !important;
    }
}