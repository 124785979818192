.skills {
    display: flex;
    max-width: 45%;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 16px;
    flex-wrap: wrap;
}
.skill {
    background-color: white;
    width: 100px;
    height: 100px;
    min-width: 100px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    box-shadow:
        0 4px 8px 0 rgb(0 0 0 / 20%),
        0 6px 20px 0 rgb(0 0 0 / 19%);
    overflow: hidden;
    cursor: pointer;
}
.skill:hover .text {
    transform: scale(1);
    transition:
        transform 300ms ease,
        opacity 300ms ease;
    opacity: 1;
}

.skill .text {
    color: #000000;
    font-weight: 600;
    font-size: 13px;
    transform: scale(0.1);
    opacity: 0;
    transition:
        transform 300ms ease,
        opacity 300ms ease;
}
.skill__icon {
    height: 50px;
    width: 50px;
}

.wrapper {
    display: flex;
    flex-direction: row;
    gap: 16px;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    max-width: 1000px;
    width: 100%;
    margin: 0 auto;
}

@media (max-width: 768px) {
    .skill .text {
        opacity: 1;
        transform: scale(1);
    }
    .skill__icon {
        height: 30px;
        width: 30px;
    }
}
