@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,700;1,400;1,500&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}

body {
  overflow-x: hidden !important;
  background-color: rgb(245, 245, 245);;
}

html {
  scroll-behavior: smooth;
}

section {
  background-color: rgb(245, 245, 245);
  transition: all 300ms ease;
  z-index: 2;
}

a {
  text-decoration: none;
  color: black;
}

li {
  list-style: none;
}

p {
  font-size: 16px;
  line-height: 1.5;
}

.container {
  padding: 50px 0;
}

.row {
  max-width: 1200px;
  padding: 0 12px;
  width: 100%;
  margin: 0 auto;
}

.link__hover-effect {
  position: relative;
}

.link__hover-effect:after {
  content: " ";
  position: absolute;
  bottom: -3px;
  right: 0;
  width: 0%;
  background-color: black;
  transition: all 300ms ease;
}

.link__hover-effect:hover::after {
  left: 0;
  width: 100%;
  height: 14%;
}

.link__hover-effect-black:hover::after {
  background-color: #242424;
}

.link__hover-effect-white:hover::after {
  background-color: white;
}

.purple {
  color: #7645d9;
}

.title {
  font-size: 120px;
  margin-bottom: 12px;
  text-align: left;
  line-height: 1;
}

.click {
  transition: all 300ms ease;
}

.click:hover {
  transform: scale(1.1);
}

.click:active {
  transform: scale(0.8);
}

.scroll {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 16px;
}

.scroll__icon {
  width: 20px;
  height: 30px;
  border: 2px solid #242424;
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.scroll__icon:after {
  content: "";
  width: 4px;
  height: 6px;
  background-color: #242424;
  border-radius: 2px;
  animation: scroll 1000ms infinite alternate-reverse;
}

.section__title {
  text-align: center;
  font-size: 48px;
  margin-bottom: 60px;
}

@keyframes scroll {
  0% {
    transform: translateY(3px);
  }

  100% {
    transform: translateY(-3px);
  }
}